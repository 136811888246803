import Cookies from 'js-cookie'
import website from '@/config/website'

const TokenKey = `${website.key}-access-token`
const RefreshTokenKey = `${website.key}-refresh-token`
const AccessTokenKey = `sso-${website.key}-token`
const SSORefreshTokenKey = `sso-${website.key}-refresh-token`

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getAccessToken() {
  return Cookies.get(AccessTokenKey)
}

export function setAccessToken(accessToken) {
  return Cookies.set(AccessTokenKey, accessToken)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function getSSORefreshToken() {
  return Cookies.get(SSORefreshTokenKey)
}

export function setSSORefreshToken(token) {
  return Cookies.set(SSORefreshTokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeAccessToken() {
  return Cookies.remove(AccessTokenKey)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}

export function removeSSORefreshToken() {
  return Cookies.remove(SSORefreshTokenKey)
}
