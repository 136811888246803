<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;

  /** 修复微应用 crud 操作菜单背景白色 */
  .avue-crud__menu {
    background-color: transparent;
    justify-content: center;
  }

  /** 修复微应用 crud 表格样式异常 */
  .avue-crud__body {
    .el-table__row {
      th {
        padding: 0 !important;
        height: 48px;
        line-height: 48px;
      }
      td {
        padding: 0 !important;
        height: 48px;
        line-height: 48px;
      }
    }
  }
}

.avue-dialog {
  /** 修复微应用 crud 操作菜单背景白色 */
  .avue-crud__menu {
    background-color: transparent;
    justify-content: center;
  }
  /** 修复微应用 crud 表格样式异常 */
  .avue-crud__body {
    .el-table__row {
      th {
        padding: 0 !important;
        height: 48px;
        line-height: 48px;
      }
      td {
        padding: 0 !important;
        height: 48px;
        line-height: 48px;
      }
    }
  }
}
</style>
