import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getToken } from './auth'
import { serialize } from './data'
import { Message } from 'element-ui'
import website from '@/config/website'
import Micro from '@gdcyy/micro-js-sdk'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 白名单
const statusWhiteList = []

// 默认超时时间
axios.defaults.timeout = 10000

// 返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500
}

// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true

// NProgress 配置
NProgress.configure({ showSpinner: false })

// http request 拦截器
axios.interceptors.request.use(
  config => {
    //开启 progress bar
    if (!Micro.isMicroApp) {
      const progressBar = config.headers['progress-bar']
      if (progressBar === undefined || progressBar) NProgress.start()
      delete config.headers['progress-bar']
    }

    // 微应用需全路径
    if (Micro.isMicroApp && website.baseUrl && website.baseUrl.length) {
      config.url = `${website.baseUrl}${config.url.replace(/^\/api/, '')}`
    }

    const meta = config.meta || {}
    const isToken = meta.isToken === false

    //让每个请求携带token
    if (getToken() && !isToken) {
      config.headers['accessToken'] = getToken()
    }
    //headers中配置text请求
    if (config.text === true) {
      config.headers['Content-Type'] = 'text/plain'
    }
    //headers中配置serialize为true开启序列化
    if (config.method === 'post' && meta.isSerialize === true) {
      config.data = serialize(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// http response 拦截
axios.interceptors.response.use(
  res => {
    //关闭 progress bar
    if (!Micro.isMicroApp) {
      NProgress.done()
    }

    //获取状态码
    const busData = (res.data || {}).data || {}
    const status = busData.code || res.data.code || res.status

    const message =
      busData.msg || res.data.msg || res.data.error_description || '未知错误'
    //如果在白名单里则自行catch逻辑处理
    if (statusWhiteList.includes(status)) return Promise.reject(res)
    //如果是401则跳转到登录页面
    if (status === 401)
      store.dispatch('FedLogOut').then(() => router.push({ path: '/login' }))
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
      Message({ message: message, type: 'error' })
      return Promise.reject(new Error(message))
    }

    return res
  },
  error => {
    NProgress.done()
    return Promise.reject(new Error(error))
  }
)

export default axios
