/**
 * 全局配置文件
 */

const __BODYEL__ = document.querySelector(
  window.__MICRO_APP_ENVIRONMENT__ ? 'micro-app-body' : 'body'
)

export default {
  // 配置主键,目前用于存储
  key: 'app-hs-interaction',

  // 若不为空则使用该值作为开发者编码，否则使用微前端框架的登录方法
  devCode: '', // njntw811

  // 若不为空则使用该值作为接口地址，否则使用代理地址
  baseUrl: __BODYEL__.getAttribute('baseurl') || ''
}
