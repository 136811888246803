/** 序列化 */
export const serialize = data => {
  let list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}

/** 数组元素去重 */
export const singleArray = (arr, key) => {
  if (!arr || !arr.length || !Array.isArray(arr) || !key || !key.length) {
    return arr
  }
  return arr.reduce((prev, cur) => {
    if (prev.findIndex(item => item[key] === cur[key]) < 0) {
      prev.push(cur)
    }
    return prev
  }, [])
}
