import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

import logs from './modules/logs'
import user from './modules/user'
import common from './modules/common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    logs,
    user,
    common
  },
  getters
})
