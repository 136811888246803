import request from '@/util/request'

export const loginByCode = code => {
  return request({
    url: '/api/oauth/sso/login-by-auth-code', // TODO: 请替换成自己的接口地址
    method: 'get',
    params: { ssoAuthCode: code }
  })
}

export const getAccountInfo = () => {
  return request({
    url: '/api/oauth/user-info', // TODO: 请替换成自己的接口地址
    method: 'get'
  })
}

export const refreshToken = refreshToken => {
  return request({
    url: '/api/oauth/refresh-token', // TODO: 请替换成自己的接口地址
    method: 'post',
    data: { refreshToken }
  })
}

export const logout = () => {
  request({
    url: '/api/oauth/logout', // TODO: 请替换成自己的接口地址
    method: 'get'
  })
}
