import { setStore, getStore } from '@/util/store'
import {
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken
} from '@/util/auth'

import {
  loginByCode,
  getAccountInfo,
  refreshToken,
  logout
} from '@/api/account'

const user = {
  state: {
    userInfo: getStore({ name: 'userInfo' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || ''
  },
  actions: {
    // 登录
    LoginByCode({ commit }, code) {
      return new Promise((resolve, reject) => {
        loginByCode(code)
          .then(res => {
            const data = res.data.data || {}
            commit('SET_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_USER_INFO', data.user_info)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    //获取用户信息
    GetAccountInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getAccountInfo()
          .then(res => {
            const data = res.data.data
            commit('SET_USER_INFO', data)
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 刷新 token
    RefreshToken({ state, commit }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken)
          .then(res => {
            const data = res.data
            commit('SET_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_USER_INFO', data)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '')
            removeToken()
            removeRefreshToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 注销
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        removeRefreshToken()
        resolve()
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken
      setStore({ name: 'refreshToken', content: state.refreshToken })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    }
  }
}

export default user
