const routes = [
  {
    path: '/',
    name: 'app-hs-interaction',
    redirect: '/home'
  },
  {
    path: '/home',
    name: '首页',
    component: () => import(/* webpackChunkName: "page" */ '@/pages/index')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import(/* webpackChunkName: "page" */ '@/pages/login')
  },
  {
    path: '/organization',
    name: '教学组织',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/views/organization')
  },
  {
    path: '/staff',
    name: '成员管理',
    component: () => import(/* webpackChunkName: "page" */ '@/views/staff')
  },
  {
    path: '/directory',
    name: '通讯录',
    component: () => import(/* webpackChunkName: "page" */ '@/views/directory')
  },
  {
    path: '/config',
    name: '配置',
    component: () => import(/* webpackChunkName: "page" */ '@/views/config')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "page" */ '@/components/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default routes
